import React from 'react';
import { TopSocial } from '../TopSocial';
import { TopMenuBrand } from '../TopMenuBrand';
import { Menu } from '../Menu';
import { MenuMobile } from '../MenuMobile';

const Header = () => (
  <div>
    <MenuMobile />
    <TopSocial />
    <TopMenuBrand />
    <Menu />
  </div>
);

export { Header };

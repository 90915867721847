import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HomePage, About, Expositors, News, Bases, Publications, Page404, NewDetail, Contact } from './views';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/nosotros/" element={<About />} />
        <Route path="/expositores/" element={<Expositors />} />
        <Route path="/noticias/:slug" element={<NewDetail />} />
        <Route path="/noticias/" element={<News />} />
        <Route path="/bases/" element={<Bases />} />
        <Route path="/publicaciones/" element={<Publications />} />
        <Route path="/contacto/" element={<Contact />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

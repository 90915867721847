import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Footer, FoundImageWP } from '../../components';
import { getMedias, getSinglePosts } from '../../redux/actions';
import './NewDetail.css';

const NewDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState({});

  const medias = useSelector((state) => state.mediasList.data);
  const loadingMedias = useSelector((state) => state.mediasList.loading);
  const postList = useSelector((state) => state.post.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedias());
  }, []);

  useEffect(() => {
    if (!loadingMedias && medias.length > 0) {
      dispatch(getSinglePosts({ slug }));
    }
  }, [loadingMedias]);

  useEffect(() => {
    if (postList.length > 0) {
      setPost(postList[0]);
    }
  }, [postList]);

  return (
    <div>
      <Header />
      <Container className="mt-5 pb-4">
        <Row>
          <Col lg={8}>
            <FoundImageWP idImage={post.featured_media} />
            <h1 className="pt-4 pb-4">{ post.title?.rendered }</h1>
            <div dangerouslySetInnerHTML={{ __html: post.content?.rendered }} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export { NewDetail };

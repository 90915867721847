import { put, takeLatest, call } from 'redux-saga/effects';
import { medias as D } from '../definitions';
import { mediaService } from '../../services';

function* getMedias({ payload }) {
  try {
    const response = yield call(mediaService, payload);
    yield put({
      type: D.GET_MEDIAS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: D.GET_MEDIAS_FAILED,
      payload: error,
    });
  }
}

const mediasSagas = [
  takeLatest(D.GET_MEDIAS_REQUESTED, getMedias),
];

export { mediasSagas };

import { put, takeLatest, call } from 'redux-saga/effects';
import { pages as D } from '../definitions';
import { pageService } from '../../services';

function* getPages({ payload }) {
  try {
    const response = yield call(pageService, payload);
    yield put({
      type: D.GET_PAGES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: D.GET_PAGES_FAILED,
      payload: error,
    });
  }
}

const pagesSagas = [
  takeLatest(D.GET_PAGES_REQUESTED, getPages),
];

export { pagesSagas };

import { put, takeLatest, call } from 'redux-saga/effects';
import { expositors as D } from '../definitions';
import { expositorService } from '../../services';

function* getExpositors({ payload }) {
  try {
    const response = yield call(expositorService, payload);
    yield put({
      type: D.GET_EXPOSITORS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: D.GET_EXPOSITORS_FAILED,
      payload: error,
    });
  }
}

const expositorsSagas = [
  takeLatest(D.GET_EXPOSITORS_REQUESTED, getExpositors),
];

export { expositorsSagas };

import { put, takeLatest, call } from 'redux-saga/effects';
import { publications as D } from '../definitions';
import { publicationsService } from '../../services';

function* getPublications({ payload }) {
  try {
    const response = yield call(publicationsService, payload);
    yield put({
      type: D.GET_PUBLICATIONS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: D.GET_PUBLICATIONS_FAILED,
      payload: error,
    });
  }
}

const publicationsSagas = [
  takeLatest(D.GET_PUBLICATIONS_REQUESTED, getPublications),
];

export { publicationsSagas };

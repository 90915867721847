import React from 'react';
import './TopSocial.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Container } from 'react-bootstrap';

const TopSocial = () => (
  <div className="sectionTopSocial">
    <Container className="containerTopSocial">
      <div className="contentTopSocial">
        <span>Síguenos en:</span>
        <a href="https://www.facebook.com/periodismo.uls/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faFacebookSquare} size="2x" className="socialIcon" />
        </a>
        <a href="https://twitter.com/periodismo_uls/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faTwitterSquare} size="2x" className="socialIcon" />
        </a>
        <a href="https://www.instagram.com/periodismo_uls/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="socialIcon" />
        </a>
      </div>
    </Container>
  </div>
);

export { TopSocial };

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Footer, Header } from '../../components';

const Page404 = () => (
  <div>
    <Header />
    <Container style={{ height: '400px' }}>
      <Row>
        <Col>
          <p className="pt-5" style={{ fontSize: '3em' }}>
            <span className="text-danger" style={{ fontSize: '3.5rem', fontWeight: 'bold' }}>404</span>
            {' '}
            Página no existe
          </p>
        </Col>
      </Row>

    </Container>
    <Footer />
  </div>
);

export { Page404 };

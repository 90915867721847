/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Footer, FoundImageWP } from '../../components';
import { getMedias, getPublications } from '../../redux/actions';
import './Publications.css';

const Publications = () => {
  const medias = useSelector((state) => state.mediasList.data);
  const loadingMedias = useSelector((state) => state.mediasList.loading);
  const publications = useSelector((state) => state.publicationsList.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedias());
  }, []);

  useEffect(() => {
    if (!loadingMedias && medias.length > 0) {
      dispatch(getPublications());
    }
  }, [loadingMedias]);
  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col>
            <h1 className="titlePublicationsPage pt-4 pb-4">Publicaciones</h1>
          </Col>
        </Row>
        <Row>
          { publications.length > 0 ? (
            <>
              { publications.map((publication) => (
                <Col key={publication.id} xs={12} lg={3}>
                  <Card className="cardPublications mb-4">
                    <FoundImageWP idImage={publication.featured_media} />
                    <Card.Body className="p-0 pt-3">
                      <Card.Title>{publication.title.rendered}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          ) : (
            <Col>
              <p className="text-center pb-5 pt-5" style={{ fontSize: '2em' }}>No existen PUBLICACIONES disponibles</p>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export { Publications };

import React from 'react';
import {
  Col, Container, Image, Row,
} from 'react-bootstrap';
import './Footer.css';

const Footer = () => (
  <div className="Footer">
    <Container>
      <Row style={{
        paddingTop: '59px', paddingBottom: '58px', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <Col xs={12} md={6} className="text-center">
          <Image className="logoFooter" src="/assets/images/logo-uls-letras-blancas.png" fluid />
        </Col>
        <Col xs={12} md={6} className="text-center">
          <Image src="/assets/images/cna.png" fluid />
        </Col>
      </Row>
    </Container>
  </div>
);

export { Footer };

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Footer } from '../../components';
import './About.css';
import { getPage, getMedias } from '../../redux/actions';

const About = () => {
  const [page, setPage] = useState({});
  const medias = useSelector((state) => state.mediasList.data);
  const loadingMedias = useSelector((state) => state.mediasList.loading);
  const pageState = useSelector((state) => state.page.data);
  const loadingPage = useSelector((state) => state.page.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedias());
  }, []);

  useEffect(() => {
    if (!loadingMedias && medias.length > 0) {
      dispatch(getPage({ slug: 'nosotros' }));
    }
  }, [loadingMedias]);

  useEffect(() => {
    if (!loadingPage) {
      setPage(pageState[0]);
    }
  }, [loadingPage]);

  return (
    <>
      <Header />
      <section>
        <Container fluid className="p-0">
          <Row>
            <Image src="/assets/images/mock/journalists.png" fluid />
          </Row>
        </Container>
      </section>
      <section>
        <Container className="pt-4 pb-4">
          <Row>
            <Col>
              <h1 className="aboutTitle mb-0">{ page?.title?.rendered }</h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <div className="aboutParagraph" dangerouslySetInnerHTML={{ __html: page?.content?.rendered }} />
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export { About };

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es-mx';
import { Header, Footer } from '../../components';
import { getMedias, getPage } from '../../redux/actions';
// import './News.css';

const Contact = () => {
  const [page, setPage] = useState({});
  const medias = useSelector((state) => state.mediasList.data);
  const loadingMedias = useSelector((state) => state.mediasList.loading);
  const pageState = useSelector((state) => state.page.data);
  const loadingPage = useSelector((state) => state.page.loading);
  //   const posts = useSelector((state) => state.postsList.data);

  const dispatch = useDispatch();
  moment().locale('es-mx');

  useEffect(() => {
    dispatch(getMedias());
  }, []);

  useEffect(() => {
    if (!loadingMedias && medias.length > 0) {
      dispatch(getPage({ slug: 'contacto' }));
    }
  }, [loadingMedias]);

  useEffect(() => {
    if (!loadingPage) {
      setPage(pageState[0]);
    }
  }, [loadingPage]);

  return (
    <div>
      <Header />
      <section className="news pb-5">
        <Container>
          <Row>
            <Col>
              <h1 className="titleNewsPage pt-4 pb-4">{ page?.title?.rendered }</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="paragraphBases" dangerouslySetInnerHTML={{ __html: page?.content?.rendered.replace('/wp-json/wp/v2/pages?slug=contacto', 'https://administracion.seminariocomunicacionuls.cl/contacto/') }} />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export { Contact };

/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const FoundImageWP = ({ idImage, rounded }) => {
  const medias = useSelector((state) => state.mediasList.data);
  const media = medias?.find((element) => element.id === idImage);
  return <Image src={media?.source_url} fluid roundedCircle={rounded} />;
};

export { FoundImageWP };

FoundImageWP.propTypes = {
  idImage: PropTypes.number,
  rounded: PropTypes.bool,
};

FoundImageWP.defaultProps = {
  idImage: 0,
  rounded: false,
};

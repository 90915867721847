import { combineReducers } from 'redux';

import { pagesList } from './pages';
import { expositorsList } from './expositors';
import { mediasList } from './medias';
import { postsList, post } from './posts';
import { publicationsList } from './publications';
import { page } from './page';

const appReducer = combineReducers({
  pagesList,
  page,
  expositorsList,
  mediasList,
  postsList,
  post,
  publicationsList,
});

const rootReducer = (state, action) => appReducer(state, action);
export default rootReducer;

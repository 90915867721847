/* eslint-disable no-undef */
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import rootSaga from './sagas';

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
export default createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));
// export default createStore(reducers, applyMiddleware(sagaMiddleware, logger));
// then run the saga// then run the saga
sagaMiddleware.run(rootSaga);

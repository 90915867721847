import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Card, Image } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es-mx';
import { Header, Slider, Footer, FoundImageWP, CountDown as renderer } from '../../components';
import { getExpositors, getMedias, getPages, getPosts, getPublications } from '../../redux/actions';
import './HomePage.css';

const HomePage = () => {
  const [pageInitial, setPageInital] = useState({});
  const [pageExpositors, setPageExpositors] = useState({});
  const [pageinscription, setPageinscription] = useState({});

  const expositors = useSelector((state) => state.expositorsList.data);
  const medias = useSelector((state) => state.mediasList.data);
  const loadingMedias = useSelector((state) => state.mediasList.loading);
  const pages = useSelector((state) => state.pagesList.data);
  const loadingPages = useSelector((state) => state.pagesList.loading);
  const posts = useSelector((state) => state.postsList.data);
  const publications = useSelector((state) => state.publicationsList.data);

  const dispatch = useDispatch();
  moment().locale('es-mx');

  useEffect(() => {
    dispatch(getMedias());
  }, []);

  useEffect(() => {
    if (!loadingMedias && medias.length > 0) {
      dispatch(getExpositors());
      dispatch(getPages());
      dispatch(getPosts());
      dispatch(getPublications());
    }
  }, [loadingMedias]);

  useEffect(() => {
    if (!loadingPages && pages.length > 0) {
      const getPageInitial = pages.find((element) => element.id === 9);
      setPageInital(getPageInitial);

      const getPageExpositors = pages.find((element) => element.id === 14);
      setPageExpositors(getPageExpositors);

      const getPageInscription = pages.find((element) => element.id === 16);
      setPageinscription(getPageInscription);
    }
  }, [loadingPages]);

  return (
    <>
      <Header />
      <Slider />
      {/* CountDown */}
      <section className="d-none d-lg-block">
        <Container>
          <Row className="contDownTitle">
            <Col>
              <h1 className="title">TIEMPO FALTANTE PARA EL SEMINARIO</h1>
            </Col>
          </Row>
        </Container>
        <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Countdown date={new Date('Nov 19, 2021 08:00:00')} renderer={renderer} />
        </Container>
      </section>
      {/* About us */}
      <Container>
        <Row>
          <Col mx={12} lg={6}>
            <h1 className="titleAboutUS">{pageInitial.title?.rendered }</h1>
            <div className="parragraphAboutUS" dangerouslySetInnerHTML={{ __html: pageInitial?.content?.rendered }} />
            <div className="text-center pt-3">
              <Button href="/nosotros/" className="buttonAboutUs" size="lg">Saber más</Button>
            </div>
          </Col>

          <Col mx={12} lg={6} className="imgAboutUs d-none d-lg-block">
            <FoundImageWP idImage={pageInitial.featured_media} />
          </Col>
        </Row>
      </Container>

      {/* Expositors */}
      <section className="sectionExpositors">
        <Container className="pb-4 pt-4">
          <Row>
            <Col mx={12} lg={5}>
              <h1 className="titleExpositors">{pageExpositors.title?.rendered }</h1>
              <div className="parragraphExpositors" dangerouslySetInnerHTML={{ __html: pageExpositors?.excerpt?.rendered }} />
              <div className="text-center pt-1">
                <Button href="/expositores/" className="buttonExpositors" size="lg" variant="outline-light">Ver más</Button>
              </div>
            </Col>

            <Col mx={12} lg={7} className="imgAboutUs d-none d-lg-block">
              <Row>
                {expositors.map((value) => (
                  <Col key={value.id} className="text-center">
                    <FoundImageWP idImage={value.featured_media} rounded />
                    <p className="nameExpositor text-center pt-2">{value.title.rendered}</p>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* INFO */}
      <section className="sectionInfo pt-4 pb-4">
        <Container>
          <Row>
            <Col
              mx={12}
              lg={5}
              className="imgAboutUs d-none d-lg-block"
            >
              <Row className="rowInfo">
                <Col xs={12} className="containerButtonInfo">
                  <div className="containerNavButton d-grid gap-2">
                    <Button className="buttonInfo" size="lg" variant="outline-light">Descargar Bases</Button>
                  </div>
                </Col>
                <Col xs={12} className="mt-4 containerButtonInfo">
                  <div className="containerNavButton d-grid gap-2">
                    <Button href="/bases/" className="buttonInfo" size="lg" variant="danger">Ver más</Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col mx={12} lg={7}>
              <h1 className="titleExpositors">{pageinscription?.title?.rendered}</h1>
              <div className="parragraphExpositors" dangerouslySetInnerHTML={{ __html: pageinscription?.excerpt?.rendered }} />
            </Col>

            <Col
              mx={12}
              lg={5}
              className="imgAboutUs"
            >
              <Row className="rowInfo d-lg-none">
                <Col xs={12} className="containerButtonInfo">
                  <div className="containerNavButton d-grid gap-2">
                    <Button className="buttonInfo" size="lg" variant="outline-light">Descargar Bases</Button>
                  </div>
                </Col>
                <Col xs={12} className="mt-4 containerButtonInfo">
                  <div className="containerNavButton d-grid gap-2">
                    <Button className="buttonInfo" size="lg" variant="danger">Ver más</Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* NEWS */}
      <section className="sectionNews pt-4 pb-4">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1 className="titleNews pb-1">Últimas Noticias</h1>
              <p className="subtitleNews pb-2">Accede a las últimas noticias acerca de los eventos y seminarios</p>
            </Col>
          </Row>
          <Row>
            {posts.map((post) => (
              <Col key={post.id} xs={12} lg={4}>
                <a href={`/noticias/${post?.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Card className="cardNews mb-4">
                    <FoundImageWP idImage={post.featured_media} />
                    <Card.Body className="p-0 pt-3">
                      <Card.Title>{post.title.rendered}</Card.Title>
                      <p style={{ color: '#1494DE' }} className="pb-2 m-0">{ `${moment(post.date).format('DD')} de ${moment(post.date).format('MMMM')} de ${moment(post.date).format('YYYY')}` }</p>
                      <div dangerouslySetInnerHTML={{ __html: post?.excerpt?.rendered }} />
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
          <Row className="mt-4">
            <Col xs={12} className="text-center">
              <Button href="/noticias/" className="buttonNews" size="lg" variant="primary">ver más noticias</Button>
            </Col>
          </Row>
        </Container>
      </section>

      {/* PUBLICATIONS */}
      { publications.length > 0 && (
        <section className="sectionPublications pt-4 pb-4">
          <Container>
            <Row className="pb-4 pt-2">
              <Col
                xs={12}
                className="text-center"
              >
                <h1 className="titlePublications">Publicaciones</h1>
              </Col>
            </Row>
            <Row>
              {publications.map((publication) => (
                <Col key={publication.id} xs={12} lg={3}>
                  <Card className="cardPublications mb-4">
                    <FoundImageWP idImage={publication.featured_media} />
                    <Card.Body className="p-0 pt-3">
                      <Card.Title>{publication.title.rendered}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row className="mt-4">
              <Col xs={12} className="text-center">
                <Button href="/publicaciones/" className="buttonNews" size="lg" variant="primary">más publicaciones</Button>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <section className="pt-4 pb-4">
        <Container>
          <Row>
            <Col xs={12} sm={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Image src="/assets/images/mock/LOGO felafacs_2.png" fluid />
            </Col>
            <Col xs={12} sm={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Image src="/assets/images/mock/LOGOTIPO ALFAMED.png" fluid />
            </Col>
            <Col xs={12} sm={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Image src="/assets/images/mock/revista-comunicar-png-04-positiva1.png" fluid />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export { HomePage };

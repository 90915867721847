import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CountDown = ({ days, hours, minutes, seconds }) => (
  <Row style={{ maxWidth: '700px', marginLeft: '0px' }}>
    <Col xs={2}>
      <p className="contDownHour">{days < 10 ? `0${days}` : days}</p>
      <p className="contDownText">Días</p>
    </Col>
    <Col xs={1}>
      <p className="contDownHour">:</p>
    </Col>
    <Col xs={2}>
      <p className="contDownHour">{hours < 10 ? `0${hours}` : hours}</p>
      <p className="contDownText">Horas</p>
    </Col>
    <Col xs={1}>
      <p className="contDownHour">:</p>
    </Col>
    <Col xs={2}>
      <p className="contDownHour">{minutes < 10 ? `0${minutes}` : minutes}</p>
      <p className="contDownText">Minutos</p>
    </Col>
    <Col xs={1}>
      <p className="contDownHour">:</p>
    </Col>
    <Col xs={2}>
      <p className="contDownHour">{seconds < 10 ? `0${seconds}` : seconds}</p>
      <p className="contDownText">Segundos</p>
    </Col>
  </Row>
);

export { CountDown };

CountDown.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
};

CountDown.defaultProps = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

/* eslint-disable max-len */
import React from 'react';
import {
  Navbar, Nav, Button, Container, Offcanvas,
} from 'react-bootstrap';
import './MenuMobileStyle.css';

const MenuMobile = () => (
  <div className="containerMenuMobile d-lg-none">
    <Navbar variant="dark" expand={false} className="navBarMobile">
      <Container fluid>
        <Navbar.Brand href="#" />
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">Menú</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="text-center">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/">INICIO</Nav.Link>
              <Nav.Link href="/nosotros/">NOSOTROS</Nav.Link>
              <Nav.Link href="/expositores/">EXPOSITORES</Nav.Link>
              <Nav.Link href="/noticias/">NOTICIAS</Nav.Link>
              {/* <Nav.Link href="/bases/">BASES</Nav.Link>
              <Nav.Link href="/galeria/">GALERÍA</Nav.Link>
              <Nav.Link href="/publicaciones/">PUBLICACIONES</Nav.Link> */}
              <Nav.Link href="/contacto/">CONTACTO</Nav.Link>
            </Nav>
            <Button href="https://docs.google.com/forms/d/e/1FAIpQLSe6scexTSxRzC-cMc5OVB2MUU9vvFBOYEo07qSGriFo__3dwg/viewform" target="_blank" variant="danger" className="mt-5">INSCRIPICIONES</Button>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>

  </div>
);

export { MenuMobile };

import React from 'react';
import { Image } from 'react-bootstrap';
import './Slider.css';

const Slider = () => (
  <div className="d-none d-lg-block">
    <Image className="imgHome" src="/assets/images/mock/banner web.png" />
  </div>
);

export { Slider };

import { pages as D } from '../definitions';

const initialState = {
  data: [],
  loading: false,
  errors: [],
};

export const pagesList = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case D.GET_PAGES_REQUESTED:
      return {
        ...state,
        loading: true,
        errors: [],
      };

    case D.GET_PAGES_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        errors: [],
      };
    case D.GET_PAGES_FAILED:
      return {
        ...state,
        data: null,
        loading: false,
        errors: payload,
      };
    default:
      return state;
  }
};

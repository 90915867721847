/* eslint-disable max-len */
import React from 'react';
import {
  Navbar, Nav, Button, Container,
} from 'react-bootstrap';
import './MenuStyle.css';

const Menu = () => (

  <div className="containerMenu d-none d-lg-block">
    <Container>
      <Navbar
        expand="lg"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="containerNavMenu">
            <Nav className="me-auto menu">
              <Nav.Link href="/" className="navLink p-0">INICIO</Nav.Link>
              <Nav.Link href="/nosotros/" className="navLink p-0">NOSOTROS</Nav.Link>
              <Nav.Link href="/expositores/" className="navLink p-0">EXPOSITORES</Nav.Link>
              <Nav.Link href="/noticias/" className="navLink p-0">NOTICIAS</Nav.Link>
              {/* <Nav.Link href="/bases/" className="navLink p-0">BASES</Nav.Link> */}
              {/* <Nav.Link href="/galeria/" className="navLink p-0">GALERÍA</Nav.Link> */}
              {/* <Nav.Link href="/publicaciones/" className="navLink p-0">PUBLICACIONES</Nav.Link> */}
              <Nav.Link href="/contacto/" className="navLink p-0">CONTACTO</Nav.Link>
            </Nav>
          </div>
          <div className="containerNavButton d-grid gap-2">
            <Button href="https://docs.google.com/forms/d/e/1FAIpQLSe6scexTSxRzC-cMc5OVB2MUU9vvFBOYEo07qSGriFo__3dwg/viewform" target="_blank" variant="danger" className="navButton">INSCRIPCIONES</Button>
          </div>
        </Navbar.Collapse>

      </Navbar>
    </Container>
  </div>
);

export { Menu };

import { put, takeLatest, call } from 'redux-saga/effects';
import { page as D } from '../definitions';
import { pageServiceBySlug } from '../../services';

function* getPage({ payload }) {
  try {
    const response = yield call(pageServiceBySlug, payload);
    yield put({
      type: D.GET_PAGE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: D.GET_PAGE_FAILED,
      payload: error,
    });
  }
}

const pageSagas = [
  takeLatest(D.GET_PAGE_REQUESTED, getPage),
];

export { pageSagas };

/* eslint-disable no-undef */
export const URL_API = 'https://administracion.seminariocomunicacionuls.cl';

export const pageService = (payload) => {
  const WORDPRESS_API_ENDPOINT = `${URL_API}/wp-json/wp/v2/pages`;

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  return fetch(WORDPRESS_API_ENDPOINT, parameters)
    .then((response) => response.json())
    .then((json) => json);
};

export const expositorService = (payload) => {
  const WORDPRESS_API_ENDPOINT = `${URL_API}/wp-json/wp/v2/expositor`;

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  return fetch(WORDPRESS_API_ENDPOINT, parameters)
    .then((response) => response.json())
    .then((json) => json);
};

export const mediaService = (payload) => {
  const WORDPRESS_API_ENDPOINT = `${URL_API}/wp-json/wp/v2/media/?per_page=100`;

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  return fetch(WORDPRESS_API_ENDPOINT, parameters)
    .then((response) => response.json())
    .then((json) => json);
};

export const postService = (payload) => {
  const WORDPRESS_API_ENDPOINT = `${URL_API}/wp-json/wp/v2/posts/`;

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  return fetch(WORDPRESS_API_ENDPOINT, parameters)
    .then((response) => response.json())
    .then((json) => json);
};

export const publicationsService = (payload) => {
  const WORDPRESS_API_ENDPOINT = `${URL_API}/wp-json/wp/v2/publicacion/`;

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  return fetch(WORDPRESS_API_ENDPOINT, parameters)
    .then((response) => response.json())
    .then((json) => json);
};

export const pageServiceBySlug = ({ slug }) => {
  const WORDPRESS_API_ENDPOINT = `${URL_API}/wp-json/wp/v2/pages?slug=${slug}`;

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(WORDPRESS_API_ENDPOINT, parameters)
    .then((response) => response.json())
    .then((json) => json);
};

export const postServiceBySlug = ({ slug }) => {
  const WORDPRESS_API_ENDPOINT = `${URL_API}/wp-json/wp/v2/posts?slug=${slug}`;

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(WORDPRESS_API_ENDPOINT, parameters)
    .then((response) => response.json())
    .then((json) => json);
};

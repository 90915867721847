import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Footer, FoundImageWP } from '../../components';
import { getExpositors, getMedias } from '../../redux/actions';
import './Expositors.css';

const Expositors = () => {
  const [pageExpositors, setPageExpositors] = useState({});
  const expositors = useSelector((state) => state.expositorsList.data);
  const medias = useSelector((state) => state.mediasList.data);
  const loadingMedias = useSelector((state) => state.mediasList.loading);
  const pages = useSelector((state) => state.pagesList.data);
  const loadingPages = useSelector((state) => state.pagesList.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedias());
  }, []);

  useEffect(() => {
    if (!loadingMedias && medias.length > 0) {
      dispatch(getExpositors());
    }
  }, [loadingMedias]);

  useEffect(() => {
    if (!loadingPages && pages.length > 0) {
      const getPageExpositors = pages.find((element) => element.id === 14);
      setPageExpositors(getPageExpositors);
    }
  }, [loadingPages]);

  return (
    <div>
      <Header />
      <section>
        <Container>
          <Row className="pt-5">
            <Col>
              <h1 className="titleExpositorsPage">{pageExpositors.title?.rendered }</h1>
            </Col>
          </Row>
          <Row className="pb-2 pt-5">
            <Col>
              <div className="expositorParagraph" dangerouslySetInnerHTML={{ __html: pageExpositors?.excerpt?.rendered }} />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          {expositors.map((value) => (
            <Row key={value.id} className="pb-2">
              <Col lg={3} className="imageExpositor">
                <FoundImageWP idImage={value.featured_media} rounded />
              </Col>
              <Col lg={9}>
                <div style={{ height: '90%' }}>
                  <h1 className="expositorTitle">{value.title.rendered}</h1>
                  <div className="expositorDescription pb-4" dangerouslySetInnerHTML={{ __html: value?.content?.rendered }} />
                </div>
                <hr />
              </Col>
            </Row>
          ))}
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export { Expositors };

import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es-mx';
import { Header, Footer, FoundImageWP } from '../../components';
import { getMedias, getPosts } from '../../redux/actions';
import './News.css';

const News = () => {
  const medias = useSelector((state) => state.mediasList.data);
  const loadingMedias = useSelector((state) => state.mediasList.loading);
  const posts = useSelector((state) => state.postsList.data);

  const dispatch = useDispatch();
  moment().locale('es-mx');

  useEffect(() => {
    dispatch(getMedias());
  }, []);

  useEffect(() => {
    if (!loadingMedias && medias.length > 0) {
      dispatch(getPosts());
    }
  }, [loadingMedias]);

  return (
    <div>
      <Header />
      <section className="news pb-5">
        <Container>
          <Row>
            <Col>
              <h1 className="titleNewsPage pt-4 pb-4">Noticias</h1>
            </Col>
          </Row>
          {posts.map((post) => (
            <Row key={post.id} className="mb-4">
              <Col lg={9}>
                <a href={post.slug} style={{ textDecoration: 'none' }}>
                  <Card>
                    <FoundImageWP idImage={post.featured_media} />
                    <Card.Body>
                      <Card.Title className="card-title">{post.title?.rendered}</Card.Title>
                      <p style={{ color: '#1494DE' }} className="pb-2 m-0">{ `${moment(post.date).format('DD')} de ${moment(post.date).format('MMMM')} de ${moment(post.date).format('YYYY')}` }</p>
                      <div dangerouslySetInnerHTML={{ __html: post?.excerpt?.rendered }} className="card-text" />
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>
          ))}
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export { News };

/* eslint-disable max-len */
import React from 'react';
import {
  Image, Container, Row, Col,
} from 'react-bootstrap';
import './TopMenuBrand.css';

const TopMenuBrand = () => (
  <div className="containerHeader">
    <Container>
      <Row className="topBrand m-0">
        <Col className="p-0 colBrand">
          <div className="containerImage">
            <Image className={['imgNavbar', 'logoUls']} src="/assets/images/logo-uls-letras-blancas.png" />
          </div>
          <div className="containerImage">
            <Image className={['imgNavbar', 'logoPeriodismo']} src="/assets/images/escuela-periodismo-logo.svg" />
          </div>
          <div className="textOverTitle">
            SEMINARIO INTERNACIONAL DE COMUNICACIÓN Y CIENCIA: INTERCULTURALIDAD, DESARROLLO SOSTENIBLE Y SOBERANÍA DIGITAL
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export { TopMenuBrand };

import { put, takeLatest, call } from 'redux-saga/effects';
import { posts as D } from '../definitions';
import { postService, postServiceBySlug } from '../../services';

function* getPosts({ payload }) {
  try {
    const response = yield call(postService, payload);
    yield put({
      type: D.GET_POSTS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: D.GET_POSTS_FAILED,
      payload: error,
    });
  }
}

function* getSinglePosts({ payload }) {
  try {
    const response = yield call(postServiceBySlug, payload);
    yield put({
      type: D.GET_POST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: D.GET_POST_FAILED,
      payload: error,
    });
  }
}

const postsSagas = [
  takeLatest(D.GET_POSTS_REQUESTED, getPosts),
  takeLatest(D.GET_POST_REQUESTED, getSinglePosts),
];

export { postsSagas };

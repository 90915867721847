import { posts as D } from '../definitions';

export const getPosts = (payload) => ({
  type: D.GET_POSTS_REQUESTED,
  payload,
});

export const getSinglePosts = (payload) => ({
  type: D.GET_POST_REQUESTED,
  payload,
});

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row, Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Footer, Header } from '../../components';
import { getMedias, getPage } from '../../redux/actions';
import './Bases.css';

const Bases = () => {
  const [page, setPage] = useState({});
  const medias = useSelector((state) => state.mediasList.data);
  const loadingMedias = useSelector((state) => state.mediasList.loading);
  const pageState = useSelector((state) => state.page.data);
  const loadingPage = useSelector((state) => state.page.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedias());
  }, []);

  useEffect(() => {
    if (!loadingMedias && medias.length > 0) {
      dispatch(getPage({ slug: 'bases' }));
    }
  }, [loadingMedias]);

  useEffect(() => {
    if (!loadingPage) {
      setPage(pageState[0]);
    }
  }, [loadingPage]);

  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col>
            <h1 className="pb-5 pt-5 titleBases">{ page?.title?.rendered }</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="paragraphBases" dangerouslySetInnerHTML={{ __html: page?.content?.rendered }} />
          </Col>
        </Row>
        <Row className="pb-5 pt-5">
          <Col className="text-center">
            <Button variant="danger" className="buttonBases">DESCARGAR BASES</Button>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export { Bases };

import { all } from 'redux-saga/effects';
import { pagesSagas } from './pagesSagas';
import { expositorsSagas } from './expositorsSagas';
import { mediasSagas } from './mediasSagas';
import { postsSagas } from './postsSagas';
import { publicationsSagas } from './publicationsSagas';
import { pageSagas } from './pageSagas';

export default function* rootSaga() {
  yield all([
    ...pagesSagas,
    ...expositorsSagas,
    ...mediasSagas,
    ...postsSagas,
    ...publicationsSagas,
    ...pageSagas,
  ]);
}
